import { Button, Form, Modal } from 'react-bootstrap'
import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { addFireAlbum } from '../../functions/firestore'

function AlbumAdd () {

  const [show, setShow] = useState(false)
  const [referer, setReferer] = useState('')
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    addFireAlbum(formData.get('album-name'), (docId) => setReferer(docId))
    handleClose()
  }

  if (referer) {
    return <Redirect to={'/editor/' + referer}/>
  }

  return (
    <>
      <Button variant='outline-primary' onClick={handleShow}>
        Создать альбом
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size='sm'
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header className='pb-0 border-0' closeButton>
            <Modal.Title>Новый альбом</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control name='album-name' type="text" placeholder="Введите название" required/>
          </Modal.Body>
          <Modal.Footer>
            <Button block variant="primary" type="submit">Создать альбом</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default AlbumAdd