import { Table } from 'react-bootstrap'
import MarkerItem from './MarkerItem'
import s from './MarkersTable.module.css'
import ModalConfirm from './ModalConfirm'
import { useState } from 'react'
import { deleteAlbumCall } from '../../../functions/firestore'

function MarkerksTable (props) {
  const { markersFire } = props
  const [show, setShow] = useState(false)
  const [markerData, setMarkerData] = useState(null)
  const handleShow = (item) => {
    setMarkerData(item)
    setShow(true)
  }
  const handleClose = () => setShow(false)

  const items = markersFire.map(item => (<MarkerItem key={item.id} item={item} onHandleShow={handleShow}/>))

  return (
    <>
      <Table responsive className={s.tableMarkers}>
        <thead className='thead-light'>
        <tr>
          <th>Название маркера</th>
          <th>Маркер</th>
          <th>Медиа</th>
          <th>Дата добавления</th>
          <th>Категория</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {items}
        </tbody>
      </Table>
      <ModalConfirm show={show} onHide={handleClose} markerData={markerData}/>
    </>
  )
}

export default MarkerksTable