import React, { createRef } from 'react'
import { Form, FormControl, InputGroup, Modal } from 'react-bootstrap'
import { changeQrCode } from '../../../../functions/firestore'
import QRCode from 'qrcode.react'
import pencilsvg from '../../../../svg/pencil-fill.svg'

class QRModal extends React.Component {
  constructor (props) {
    super(props)
    this.inputQrRef = createRef()
  }

  handleSubmit = evt => {
    evt.preventDefault()
    changeQrCode(this.inputQrRef.current.value, this.props.albumId)
    this.inputQrRef.current.disabled = true
  }

  handleEditQrActive = () => {
    this.inputQrRef.current.disabled = false
    this.inputQrRef.current.focus()
  }

  render () {
    return (
      <>
        <Modal centered show={this.props.show} onHide={() => this.props.onHide()} size='sm' animation={false}>
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header className='border-bottom-0' closeButton>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center' }}>

              <QRCode value={this.props.qrCode} renderAs={'svg'} size={200}/>

              <InputGroup size="sm mt-4">
                <FormControl disabled={true} placeholder={this.props.qrCode} ref={this.inputQrRef}/>
                <InputGroup.Append>
                  <InputGroup.Text onDoubleClick={this.handleEditQrActive}><img src={pencilsvg}/></InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>

            </Modal.Body>
            <Modal.Footer className='justify-content-center border-top-0'>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    )
  }
}

export default QRModal
