import { Alert, Button, Modal } from 'react-bootstrap'

function ModalConfirm (props) {

  const confirmDelete = () => {
    props.onDeleteAlbum(props.albumData.id)
    props.onHide()
  }

  return (
    <>
      <Modal show={props.show} onHide={props.onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Подтверждение удаления</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant='danger'>
            Внимание! Альбом <strong>{props.albumData?.albumName}</strong> будет удален безвозвратно
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Отмена
          </Button>
          <Button variant="primary" onClick={confirmDelete}>
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalConfirm