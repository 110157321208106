import ModalSign from './ModalSign/ModalSign'
import { useEffect, useState } from 'react'

function SignPage () {

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(()=>{
    handleShow()
  })


  return (
    <>
      <ModalSign show={show} onHide={handleClose}/>
    </>
  )
}

export default SignPage