import { Card } from 'react-bootstrap'
import s from './MetkaItem.module.css'
import React, { useState } from 'react'

const MarkerItem = (props) => {

  const [styleSection, setStyle] = useState({ color: 'grey' })
  const loadImg = (e) => {

    const koef = e.target.naturalWidth / e.target.naturalHeight
    const width = Math.round(koef * 200)
    const grow = koef * 10

    setStyle({
      width: width,
      flexGrow: grow.toFixed(2),
    })
  }

  let content
  if (props.image) {
    content = <Card.Img onLoad={loadImg} className={s.container_img} src={props.image} alt='Alt'/>
  } else {
    content = <div className={s.moderator}><span>Модерация маркера...</span></div>
  }
  return (
    <section className={s.sectionFile} style={styleSection}>
      <Card className='metka-item'>
        {content}
        <Card.Body>
          <Card.Title>{props.markerName}</Card.Title>
        </Card.Body>
      </Card>
    </section>
  )
}

export default MarkerItem
