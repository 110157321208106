import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import CategorySelect from './CategorySelect/CategorySelect'
import s from './MarkerAdd.module.css'

function prepareFiles1 (multiFiles, category) {
  let photos = []
  let medias = []

  multiFiles.forEach((item, index) => {

      const regEx = /\.[^/.]+$/
      const match = item.name.match(regEx) || []

      const name = item.name.replace(regEx, '')

      if (match[0] === '.jpg') {
        photos.push({ idx: index, name })
      }

      if (match[0] === '.mp4') {
        medias.push({ idx: index, name })
      }

    },
  )

   const markersFind =  photos.map((item) => {
    let matchIndex = medias.findIndex(i => i.name === item.name)

    if (matchIndex > -1) {
      const mediaId = medias[matchIndex].idx
      const markerId = item.idx
      return { marker: multiFiles[markerId], media: multiFiles[mediaId], name: item.name, category }
    }
  })

  if(!markersFind.includes(undefined) && markersFind.length > 0){
    return markersFind
  }
}

function ModalForm ({ show, onHide, onCreateMarker, categories }) {

  const [category, setCategory] = useState('Без категории')

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const marker = formData.get('marker-file')
    const media = formData.get('media-file')
    const markerName = formData.get('marker-name')
    const multiFiles = formData.getAll('multi-files')
    const name = markerName ? markerName : marker.name.replace(/\.[^/.]+$/, '')

    const prepareFiles = prepareFiles1(multiFiles, category)

    if (prepareFiles) {
      onCreateMarker(prepareFiles)
    } else if (marker.size === 0 || media.size === 0) {
      alert('Заполните форму. Неверные данные')
      return
    } else {
      onCreateMarker([{ marker, media, name, category }])
    }

    onHide()
  }

  return (
    <Modal centered show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>

        <Modal.Header className='pb-0 border-0' closeButton>
          <Modal.Title>Новый маркер</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} sm='9' controlId="markerNameControl">
              <Form.Label>Имя маркера</Form.Label>
              <Form.Control type="text" name='marker-name'/>
            </Form.Group>
            <Form.Group as={Col} sm='9' controlId="categoryControl">
              <Form.Label>Категория</Form.Label>
              <CategorySelect categories={categories} onSelectCategory={(cat) => setCategory(cat.label)}/>
              <Form.Text id="сategorySelecthelp" muted>
                Для новой категории введите название и нажмите Enter
              </Form.Text>
            </Form.Group>
          </Row>
          <Row>
            <Col sm>
              <label className={s.upload}>Загрузить маркер
                <input name='marker-file' type='file' accept=".jpg, .jpeg" hidden/>
              </label>
            </Col>
            <Col sm>
              <label className={s.upload}>Загрузить медиа
                <input name='media-file' type='file' accept=".mp4" hidden/>
              </label>
            </Col>
            <Col sm={12} className='text-center'>

              <label className={s.uploadLink}>Мультизагрузка
                <input name='multi-files' multiple type='file' accept=".jpg, .mp4" hidden/>
              </label>

            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-center '>
          <Button variant='primary' type='submit'>
            Создать маркер
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ModalForm