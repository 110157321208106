import { Alert, Button, Modal } from 'react-bootstrap'
import { deleteMarker } from '../../../functions/firestore'
import { deleteFile } from '../../../functions/firestorage'

function ModalConfirm (props) {

  const confirmDelete = () => {
    deleteMarker(props.markerData.albumId, props.markerData.id)
    deleteFile(props.markerData.albumId, props.markerData.markerFile)
    deleteFile(props.markerData.albumId, props.markerData.mediaFile)
    deleteFile(props.markerData.albumId, 'thumbs/thumb_'+props.markerData.markerFile)
    props.onHide()
  }

  return (
    <>
      <Modal show={props.show} onHide={props.onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Подтверждение удаления</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant='danger'>
            Внимание! Маркер <strong>{props.markerData?.markerName}</strong> будет удален безвозвратно.
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Отмена
          </Button>
          <Button variant="primary" onClick={confirmDelete}>
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalConfirm