import { Button, Form, Modal } from 'react-bootstrap'
import { signIn } from '../../../functions/auth'

function ModalSign (props) {
  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    signIn(formData.get('login'), formData.get('password'))
    props.onHide()
  }

  return (
    <>
      <Modal
        {...props}
        centered
        size='sm'
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header className='pb-0 border-0' closeButton>
            <Modal.Title>Авторизация</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Control name='login' type="text" placeholder="Введите имя" required/>
            </Form.Group>
            <Form.Group>
              <Form.Control name='password' type="password" placeholder="Введите пароль" required/>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button block variant="primary" type="submit">Войти</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default ModalSign