import React from 'react'
import { getAlbumInfo, getFirestoreMarkers } from '../../functions/firestore'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import MarkerAdd from './MarkerAdd/MarkerAdd'
import MarkersGrid from './MarkersGrid/MarkersGrid'
import MarkerksTable from './MarkersTable/MarkerksTable'
import s from './Markers.module.css'

class Markers extends React.Component {

  constructor (props) {
    super(props)
    this.state = { isLoading: false, view: 'table' }
  }

  componentDidMount () {
    getFirestoreMarkers(this.props.albumId,
      data => {
        this.setState({
          markersFire: data,
        })
      },
    )
    getAlbumInfo(this.props.albumId, data => {
      this.setState({
        albumInfo: data,
      })
    })
  }

  handleViewGrid = () => {
    this.setState({ view: 'grid' })
  }

  handleViewTable = () => {
    this.setState({ view: 'table' })
  }

  render () {
    const { markersFire, albumInfo, view } = this.state
    let activeGrid
    let activeTable
    let markersData

    if (markersFire) {
      if (view === 'grid') {
        markersData = <MarkersGrid content={markersFire}/>
        activeGrid = 'active'
      } else if (view === 'table') {
        markersData = <MarkerksTable markersFire={markersFire}/>
        activeTable = 'active'
      }
    }

    return (
      <Container fluid >
        <h1 className="mt-5 mb-4 h3">{albumInfo?.albumName}</h1>
        <hr/>
        <MarkerAdd albumId={albumInfo?.id} categories={albumInfo?.categories}/>
        <Row className='align-items-end mb-4'>
          <Col className='col-auto'><span>Всего маркеров {albumInfo?.countMarkers}</span></Col>
          <Col className={`col-auto ml-auto ${s.view}`}>
            <div className={`${s.tableButton} ${s[activeTable]}`} onClick={this.handleViewTable}/>
            <div className={`${s.gridButton} ${s[activeGrid]}`} onClick={this.handleViewGrid}/>
          </Col>
        </Row>

        {markersData}
        {!markersFire && <Spinner animation="border" variant="secondary"/>}
      </Container>
    )
  }
}

export default Markers