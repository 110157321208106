import firebase from 'firebase/app'
import 'firebase/auth'

export function getUser () {
  return firebase.auth().currentUser
}

export function signOut () {
  firebase.auth().signOut().then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  })
}

export function signIn (email, pass) {
  //const email = 'albert.alfamarket@gmail.com'
  //const pass = '123456'
  firebase.auth().signInWithEmailAndPassword(email, pass).then(user => {console.log(user)}).catch(e => alert(e.message))
}

export function checkAuth (callback) {
  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      callback(user)
    } else {
      callback(false)
      console.log('Не авторизован')
    }
  })
}

export function getAuthToken(){
  firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
    console.log(idToken)
  }).catch(function(error) {
    // Handle error
  });
}
