import React from 'react'
import ReactDOM from 'react-dom'
import firebase from 'firebase/app'
import 'firebase/database'
import 'bootstrap/dist/css/bootstrap.min.css'
import './css/App.css'
import App from './App'

const firebaseConfig = {
  apiKey: "AIzaSyBOuIl5Sd4_awIpe5M8A3HaGQ3QCkR31DU",
  authDomain: "graduate-2f8f4.firebaseapp.com",
  projectId: "graduate-2f8f4",
  storageBucket: "graduate-bucket",
  messagingSenderId: "972956830845",
  appId: "1:972956830845:web:53b5d7b43646d0525b3514",
  measurementId: "G-XZLQZCDGVZ"
};

firebase.initializeApp(firebaseConfig)

ReactDOM.render(<App/>, document.getElementById('root'))
