import React from 'react'
import { Table } from 'react-bootstrap'
import AlbumItem from './AlbumItem/AlbumItem'
import QRModal from './QRModal/QRModal'
import { deleteAlbumCall } from '../../../functions/firestore'
import ModalConfirm from '../../Albums/AlbumsTable/ModalConfirm'

class AlbumsTable extends React.Component {

  constructor (props) {
    super(props)
    this.handleQrShow = this.handleQrShow.bind(this)
    this.handleQrClose = this.handleQrClose.bind(this)
    this.handleCloseConfirm = this.handleCloseConfirm.bind(this)
    this.handleShowConfirm = this.handleShowConfirm.bind(this)
    this.handleDeleteAlbum = this.handleDeleteAlbum.bind(this)
    this.state = { qrShow: false, confirmShow: false }
  }

  handleQrShow (qrCode, itemId) {
    this.setState((state) => ({ qrShow: !state.qrShow, qrCode: qrCode, albumId: itemId }))
  }

  handleQrClose () {
    this.setState({ qrShow: false })
  }

  handleCloseConfirm(){
    this.setState({confirmShow: false})
  }

  handleShowConfirm(item){
    this.setState({confirmShow: true, albumDelete: item})
  }

  handleDeleteAlbum (albumId) {
    this.setState({ isDeliting: true })
    deleteAlbumCall(albumId, () => this.setState({ isDeliting: false }))
  }

  render () {
    const { albumsData } = this.props

    return (
      <>
        <QRModal show={this.state.qrShow} onHide={this.handleQrClose} qrCode={this.state.qrCode} albumId={this.state.albumId}/>
        <ModalConfirm show={this.state.confirmShow} onHide={this.handleCloseConfirm} onDeleteAlbum={this.handleDeleteAlbum} albumData={this.state.albumDelete} />

        <Table responsive className='text-nowrap'>
          <thead className='thead-light'>
          <tr>
            <th>Название</th>
            <th>QR</th>
            <th>Выкл/Вкл</th>
            <th>Количество маркеров</th>
            <th>Сканировано</th>
            <th>Дата создания</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {albumsData.map((item) => (<AlbumItem key={item.id} isDeliting={this.state.isDeliting} item={item} handleQrShow={this.handleQrShow} onShowConfirm={this.handleShowConfirm}/>))
          }
          </tbody>
        </Table>

        {!albumsData.length &&
        <div>Альбомов нет</div>
        }
      </>
    )
  }
}

export default AlbumsTable