import './Sidebar.css'
import { Link } from 'react-router-dom'
import ListIcon from '../../svg/list.svg'
import arbadeicon from '../../svg/badge-ar.svg'
import houseicon from '../../svg/house-door-fill.svg'
import journalicon from '../../svg/journal-album.svg'

const SidebarData = [
  {
    title: 'Главная',
    path: '/',
    cName: 'nav-text',
    icon: houseicon,
  },
  {
    title: 'Альбомы',
    path: '/albums',
    cName: 'nav-text',
    icon: journalicon,
  },
  {
    title: 'Заказы',
    path: '/orders',
    cName: 'nav-text',
    icon: arbadeicon,
  },
]

function Sidebar ({ open, onToggleMenu }) {

  return (
    <>
      <div style={open ? { width: '240px' } : { width: '80px' }}>
      </div>
      <nav className='sidebar'>
        <div className='menu-bars'>
          <Link to='#' >
            <img src={ListIcon} onClick={onToggleMenu}/>
          </Link>
        </div>
        <div className='items'>
          <ul>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className='nav-text-sidebar'>
                  <Link to={item.path} >
                    <img  src={item.icon} />
                    <span style={{width: '100%', display: 'block', textAlign: 'center', paddingTop: '3px'}}>{item.title}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </nav>
      <nav className={open ? 'nav-menu active' : 'nav-menu'}>
        <div style={{ borderBottom: '1px solid #182557', display: 'flex', width: '100%', padding: '15px 0 10px 25px' }}>
          <Link to='#'>
            <img src={ListIcon} onClick={onToggleMenu} />
          </Link>
          <div style={{ paddingLeft: '15px', fontSize: '26px', color: '#e1e1e1' }}>Graduate</div>
        </div>

        <ul className='nav-menu-items'>
          {SidebarData.map((item, index) => {
            return (
              <li key={index} className={item.cName}>
                <Link to={item.path}>
                  <img src={item.icon} style={{ marginRight: '20px'}}/>
                  <span>{item.title}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </nav>
    </>

  )
}

export default Sidebar