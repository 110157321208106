import { Badge, Nav } from 'react-bootstrap'
import React from 'react'

class AlbumTabs extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    const styleBadge = {
      color: '#495057de',
      verticalAlign: 'middle',
    }
    return (
      <>
        <Nav variant='pills' defaultActiveKey="active" className='mb-3' onSelect={(key) => this.props.onSelect(key)}>
          <Nav.Item>
            <Nav.Link eventKey="active">Активные <Badge variant="light" style={styleBadge}>{this.props.albumsCount?.active}</Badge></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="deactive">Неактивные <Badge variant="light" style={styleBadge}>{this.props.albumsCount?.deactive}</Badge></Nav.Link>
          </Nav.Item>

        </Nav>
      </>
    )
  }
}

export default AlbumTabs