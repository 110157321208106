import React from 'react'
import { Button, Col, ProgressBar, Row } from 'react-bootstrap'
import ModalForm from './ModalFom'
import { uploadFile } from '../../../functions/firestorage'
import { saveMarkerFirestore } from '../../../functions/firestore'
import s from './MarkerAdd.module.css'

import { customAlphabet } from 'nanoid'

const nanoid = customAlphabet('1234567890abcdefghigklmnopqrstuvwxyz', 11)

class MarkerAdd extends React.Component {

  constructor (props) {
    super(props)
    this.state = { show: false, progress: 0, isLoading: false }
    this.createMarker = this.createMarker.bind(this)
    this.getProgress = this.getProgress.bind(this)
    this.createMarker = this.createMarker.bind(this)
    this.progressFiles = {}
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevState.progress === 100) {
      this.progressFiles = {}
      this.setState({ progress: 0, isLoading: false })
    }
  }

  handleClose = () => this.setState({ show: false })
  handleShow = () => this.setState({ show: true })

  getProgress (data) {
    const { fileName, transfered } = data
    let totalTransfered = 0

    this.progressFiles[fileName] = { transfered: transfered }

    for (let key in this.progressFiles) {
      if (this.progressFiles[key].transfered) {
        totalTransfered = totalTransfered + this.progressFiles[key].transfered
      }
    }

    let uploaderValue = Math.round((totalTransfered / this.totalBytes) * 100)
    this.setState({ progress: uploaderValue })
  }

  async createMarker (markersForm) {
    const albumId = this.props.albumId
    this.totalBytes = 0

    markersForm.forEach(item => {
      this.totalBytes = this.totalBytes + item.marker.size + item.media.size
    })

    this.setState({ isLoading: true })

    for (let item of markersForm) {

      await new Promise(async (resolve) => {
        const id = nanoid()
        const newMarker = {
          markerName: item.name,
          category: item.category,
          markerFile: `marker-${id}`,
          mediaFile: `media-${id}`,
        }

        newMarker.markerUrl = await uploadFile(item.marker, `${albumId}/${newMarker.markerFile}`, this.getProgress)
        newMarker.mediaUrl = await uploadFile(item.media, `${albumId}/${newMarker.mediaFile}`, this.getProgress)
        saveMarkerFirestore(albumId, newMarker)

        resolve(1)
      })
    }
  }

  render () {
    const { progress, isLoading } = this.state

    return (
      <>
        <Row>
          <Col className='col-auto mr-auto'>
            <Button onClick={this.handleShow} variant='outline-primary' disabled={!this.props.albumId}>Создать маркер</Button>
          </Col>
          <Col sm='2'>
            {isLoading &&
            <div className={s.progress}>
              <ProgressBar style={{ height: '5px', marginBottom: '5px' }} now={progress}/>
              Загружено {progress}%
            </div>}
          </Col>
        </Row>
        <ModalForm show={this.state.show} categories={this.props.categories} onHide={this.handleClose} onCreateMarker={this.createMarker}/>
      </>
    )
  }
}

export default MarkerAdd