import React from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { changeAlbumStatus, deleteAlbumCall } from '../../../../functions/firestore'
import qrsvg from '../../../../svg/qr.svg'
import deletesvg from '../../../../svg/archive.svg'
import s from './AlbumItem.module.css'

class AlbumItem extends React.Component {

  constructor (props) {
    super(props)
    this.state = { switchChecked: this.props.item.status.active, switchDisabled: false }
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
  }

  handleSwitchChange (e) {
    this.setState(state => ({ switchChecked: e.target.checked, switchDisabled: !state.switchDisabled }))
    setTimeout(() => changeAlbumStatus(this.props.item.id, e.target.checked), 500)
  }

  render () {
    const { id, albumName, created, countMarkers, scaned, qrCode } = this.props.item

    console.log(created)
    const date = created.toDate()
    const Switch = (<Form.Switch
      id={id}
      label=""
      checked={this.state.switchChecked}
      onChange={this.handleSwitchChange}
      disabled={this.state.switchDisabled && true}
    />)

    const isDeletinig = this.props.isDeliting

    const deleteButton = <img src={deletesvg} className={s.archiveImg} title='Удалить'
                              onClick={()=>this.props.onShowConfirm(this.props.item)}/>

    return (
      <tr className={s.albumitem}>
        <td><NavLink to={'editor/' + id}>{albumName}{isDeletinig && <Spinner animation="grow" variant="danger" size="sm" />}</NavLink></td>
        <td><img className='album-qr-code' src={qrsvg} onClick={() => {this.props.handleQrShow(qrCode, id)}}/></td>
        <td>{Switch} </td>
        <td>{countMarkers}</td>
        <td>{scaned}</td>
        <td>{date.toLocaleString()}</td>
        <td>{!this.props.item.status.active && deleteButton}
        </td>
      </tr>
    )
  }
}

export default AlbumItem