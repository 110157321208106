import React from 'react'

class Orders extends React.Component {


  render () {

    return null
  }
}

export default Orders