import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

export function getFirestoreAlbumsReal (callback, status) {

  const db = firebase.firestore()
  let user = firebase.auth().currentUser
  if (user == null) return

  const statusQuery = (status === 'active')
  db.collection('users-content/' + user.uid + '/albums/').
    where('status.active', '==', statusQuery).orderBy('created', 'desc').
    onSnapshot((snap) => {

      let data = []
      snap.forEach(doc => {
        data.push(
          Object.assign({ id: doc.id }, doc.data()),
        )
      })

      callback(data)
    })
}

export function getFirestoreMarkers (albumId, callback) {
  let user = firebase.auth().currentUser

  firebase.firestore().
    collection(`/users-content/${user.uid}/albums/${albumId}/markers/`).
    orderBy('category', 'desc').
    orderBy('created', 'desc').
    onSnapshot(snap => {
      let data = []
      snap.forEach(doc => {
        data.push(
          Object.assign({ id: doc.id, albumId: albumId }, doc.data()),
        )
      })

      callback(data)
    })
}

export function getFireCountAlbumsReal (callback) {
  let user = firebase.auth().currentUser
  firebase.firestore().collection('users-content').doc(user.uid).onSnapshot(snap => {
    callback(snap.data().albumsCount)
  })
}

export function saveMarkerFirestore (albumId, newMarker) {
  newMarker.created = firebase.firestore.Timestamp.fromDate(new Date())
  let user = firebase.auth().currentUser
  newMarker.versMedia = 0
  newMarker.versMarker = 0
  firebase.firestore().collection(`users-content/${user.uid}/albums/${albumId}/markers`).add(newMarker).then((doc) => {
    firebase.firestore().collection(`users-content/${user.uid}/albums/`).doc(albumId).update({ countMarkers: firebase.firestore.FieldValue.increment(1) })

    const generateThumb = firebase.app().functions('europe-west1').httpsCallable('function-1')
    generateThumb({ markerId: doc.id, albumId: albumId, file: newMarker.markerFile })
  })
}

export function changeAlbumStatus (albumId, status) {
  let user = firebase.auth().currentUser
  let ref = firebase.firestore().collection(`users-content/${user.uid}/albums`).doc(albumId)
  ref.update({ 'status.active': status }).then(() => {
    console.log('async status update')
  })

  if (status === true) {
    incrementAlbumCnt('active')
  }
  if (status === false) {
    incrementAlbumCnt('deactive')
  }

}

function incrementAlbumCnt (status) {
  let update = []
  if (status === 'active') {
    update = [['active', 1], ['deactive', -1]]
  }
  if (status === 'deactive') {
    update = [['active', -1], ['deactive', 1]]
  }
  if (status === 'new') {
    update = [['active', 1]]
  }

  let obj = {}
  for (let item of update) {
    obj[`albumsCount.${item[0]}`] = firebase.firestore.FieldValue.increment(item[1])
  }

  let user = firebase.auth().currentUser
  let ref = firebase.firestore().collection('users-content').doc(user.uid)
  ref.update(obj)
}

export function deleteFireMarker (key) {

}

export function addFireAlbum (name, callback = () => {}) {
  let user = firebase.auth().currentUser
  let ref = firebase.firestore().collection(`users-content/${user.uid}/albums`).doc()

  const date = new Date()
  let year = String(date.getFullYear())
  ref.set({
    //albumName: `Новый ${date.toLocaleDateString().slice(0, -4).replace(/[.]/gi, '/')}${year.slice(2)}-${date.toLocaleTimeString()}`,
    albumName: name,
    countMarkers: 0,
    created: firebase.firestore.Timestamp.fromDate(new Date()),
    qrCode: ref.id,
    categories: [],
    scaned: 0,
    status: {
      active: true,
    },
  }).then((r) => {
    console.log('Album added')
    callback(ref.id)
    incrementAlbumCnt('new')
  })
}

export function changeQrCode (newQr, albumId) {
  let user = firebase.auth().currentUser
  let ref = firebase.firestore().collection(`users-content/${user.uid}/albums/`).doc(albumId)
  return ref.update(
    { qrCode: newQr },
  )
}

export function addCategoryAlbum (albumId, category) {
  let user = firebase.auth().currentUser
  let ref = firebase.firestore().collection(`users-content/${user.uid}/albums/`).doc(albumId)
  ref.update({
    categories: firebase.firestore.FieldValue.arrayUnion(category),
  })
}

export function getAlbumInfo (albumId, callbackState) {
  let user = firebase.auth().currentUser
  firebase.firestore().collection(`users-content/${user.uid}/albums/`).doc(albumId).onSnapshot(doc => {
    callbackState(Object.assign({ id: doc.id }, doc.data()))
  })
}

export function deleteMarker (albumId, markerId) {
  let user = firebase.auth().currentUser

  firebase.firestore().collection(`users-content/${user.uid}/albums/${albumId}/markers`).doc(markerId).delete().then(() => {
    console.log('Document successfully deleted!')
    firebase.firestore().collection(`users-content/${user.uid}/albums/`).doc(albumId).update({ countMarkers: firebase.firestore.FieldValue.increment(-1) })
  }).catch((error) => {
    console.error('Error removing document: ', error)
  })
}

export function deleteAlbumCall (albumId, callback) {
  const deleteAlbum = firebase.app().functions('europe-west1').httpsCallable('deleteAlbum')
  deleteAlbum({ albumId: albumId }).then(() => callback())
}