import React from 'react'
import Creatable from 'react-select/creatable'

const createOption = (label) => ({
  label,
  value: label.toLowerCase(),
})

const defaultOptions = [
  createOption('Без категории'),
  createOption('Общий'),
  createOption('Портрет'),
  createOption('Виньетка'),
]

class CategorySelect extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      value: defaultOptions[0],
      options: defaultOptions,
    }
  }

  handleChange = newValue => {
    this.setState({ value: newValue })
    this.props.onSelectCategory(newValue)
    console.log(newValue)
  }

  /*
  handleCreate = inputValue => {
    this.setState({ isLoading: true })
    setTimeout(() => {
      const { options } = this.state
      const newOption = createOption(inputValue)
      this.setState({
        isLoading: false,
        options: [...options, newOption],
        value: newOption,
      })
      this.props.callbackAddCategory(newOption)
    }, 1000)
  }*/

  handleCreate = ()=>{
    return
  }

  componentDidMount () {
    this.fireOptions = this.props.categories.map(item => createOption(item))
    this.setState(state => ({ options: [...state.options, ...this.fireOptions] }))
  }

  render () {
    const { options, value, isLoading } = this.state
    return (
      <Creatable
        placeholder='Выберите или введите новую'
        value={value}
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={this.handleChange}
        onCreateOption={this.handleCreate}
        options={options}/>
    )
  }
}

export default CategorySelect