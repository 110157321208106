import React from 'react'
import Albums from './components/Albums/Albums'
import Orders from './components/Orders/Orders'
import { checkAuth, getAuthToken, signOut } from './functions/auth'
import SignPage from './components/SignPage/SignPage'
import Markers from './components/Markers/Markers'
import Sidebar from './components/Sidebar/Sidebar'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'

class App extends React.Component {

  constructor (props) {
    super(props)
    this.state = { user: null, menuOpen: false }
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  componentDidMount () {
    checkAuth((user) => {
      this.setState({ user: user })
    })

  }

  toggleMenu () {
    this.setState(state => ({ menuOpen: !state.menuOpen }))
  }

  render () {

    if (this.state.user === null) {
      return null
    }

    if (this.state.user === false) {
      return <SignPage/>
    }

    return (
      <>
        <BrowserRouter>

          <Container fluid>

            <Row className='flex-sm-nowrap'>
              <Col className='col-sm-auto p-0 m-0'>
                <Sidebar open={this.state.menuOpen} onToggleMenu={this.toggleMenu}/>
              </Col>
              <Col>
                <button onClick={signOut}>Выйти</button>
                <Switch>
                  <Route path='/editor/:id' render={({ match }) => (<Markers albumId={match.params.id}/>)}/>
                  <Route path='/albums' component={Albums}/>
                  <Route path='/orders' component={Orders}/>
                </Switch>

              </Col>
            </Row>
          </Container>
        </BrowserRouter>


      </>
    )
  }
}

export default App