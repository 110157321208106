import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/storage'

export function uploadFile (file, fileName, callbackProgress) {
  return new Promise(function (resolve, reject) {

    let user = firebase.auth().currentUser
    if (user == null) return

    let storageRef = firebase.storage().
      ref(`users-content/${user.uid}/albums/${fileName}`)
    let task = storageRef.put(file)

    //Update progress bar
    task.on('state_changed',
      function progress (snapshot) {
        let uploaderValue = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        callbackProgress({ total: snapshot.totalBytes, transfered: snapshot.bytesTransferred, fileName })
        //console.log('Upload is ' + uploaderValue + '% done')

      },
      function error (err) {
        //console.log(err)
        reject(err)
      },
      async function complete () {
        const imageURL = await task.snapshot.ref.getDownloadURL()

        //console.log('imageUrl: ' + imageURL)
        resolve(imageURL)
      },
    )
  })
}

export function deleteFile (albumId, file) {
  // Create a reference to the file to delete
  let user = firebase.auth().currentUser
  const desertRef = firebase.storage().ref(`users-content/${user.uid}/albums/${albumId}/${file}`)

  desertRef.delete().then(() => {
    // File deleted successfully
  }).catch((error) => {
    // Uh-oh, an error occurred!
  })

}