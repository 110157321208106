import s from './MarkersTable.module.css'
import trashIcon from '../../../icons/trash.svg'
import { useState } from 'react'


function MarkerItem (props) {
  const [thumb, showThumb] = useState(false)
  const { markerName, markerFile, mediaFile, category, created, thumbUrl, id, albumId } = props.item
  const deleteButton = <img src={trashIcon} className={s.deleteMetka} title='Удалить' onClick={() => props.onHandleShow(props.item)} alt=''/>
  const date = created?.toDate()

  return (
    <tr className={s.actionTr}>
      <td>{markerName}</td>
      <td className={s.class1}>
        <div style={{ dispay: 'inline' }} onMouseEnter={() => showThumb(true)} onMouseLeave={() => showThumb(false)}>
          {
            thumb && <img className={s.styleThumb} src={thumbUrl}/>
          }
          {markerFile}
        </div>
      </td>
      <td>{mediaFile}</td>
      <td>{date.toLocaleString()}</td>
      <td>{category}</td>
      <td>{deleteButton}</td>
    </tr>
  )
}

export default MarkerItem