import React from 'react'
import MarkerItem from './MarkerItem'
import s from './MarkersGrid.module.css'

function MarkersGrid (props) {

  const content = props.content
  let markers = content.map((item) => (<MarkerItem key={item.id} image={item.thumbUrl} markerName={item.markerName} />))

  return (
    <div className={s.flexFiles}>
      {!markers.length &&
      <div>Маркеров нет</div>
      }
      {markers}
    </div>
  )
}

export default MarkersGrid