import React from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { getFireCountAlbumsReal, getFirestoreAlbumsReal } from '../../functions/firestore'
import AlbumsTable from './AlbumsTable/AlbumsTable'
import AlbumAdd from './AlbumAdd'
import AlbumTabs from './AlbumTabs/AlbumTabs'
import { checkAuth } from '../../functions/auth'

class Albums extends React.Component {

  constructor (props) {
    super(props)
    this.state = { albumsTab: 'active' }
    this.handleTabSelect = this.handleTabSelect.bind(this)
  }

  handleTabSelect (eventKey) {
    this.setState({ albumsTab: eventKey })
  }

  componentDidMount () {
    checkAuth(() => {
      getFirestoreAlbumsReal(data => {
        this.setState({
          activeAlbums: data,
        })
      }, 'active')

      getFirestoreAlbumsReal(data => {
        this.setState({
          deactiveAlbums: data,
        })
      }, 'deactive')

      getFireCountAlbumsReal(data => {
        this.setState({
          albumsCount: data,
        })
      })
    })
  }

  render () {
    const { albumsTab, albumsCount } = this.state
    let albumsData
    if (albumsTab === 'active') {
      albumsData = this.state.activeAlbums
    }
    if (albumsTab === 'deactive') {
      albumsData = this.state.deactiveAlbums
    }

    return (
      <Container fluid >
        <Row className='mt-5'>
          <Col><h1 className="h3">Мои альбомы</h1>
            <hr/>
          </Col>

        </Row>
        <Row className='mb-2'>
          <Col className='col-auto mr-auto'>
            <AlbumTabs onSelect={this.handleTabSelect} albumsCount={albumsCount}/>
          </Col>
          <Col className='col-auto'>
            <AlbumAdd/>
          </Col>
        </Row>

        {albumsData
          ? <AlbumsTable albumsData={albumsData}/>
          : <Spinner animation="border" variant="secondary"/>
        }
      </Container>
    )
  }
}

export default Albums
